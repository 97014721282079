<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index" @click="getUserInfo(item)">
                <div>
                    <img :src="item.avatar ? item.avatar : require('../../assets/images/default_avatar.jpeg')" alt="" />
                    <span class="title">{{ item.nickName }}</span>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getUserList" @current-change="getUserList" :current-page.sync="page" :page-sizes="[12, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'about',
    data() {
        return {
            limit: 12,
            page: 1,
            total: 0,
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '社群',
                    to: { path: '/communityList' },
                },
                {
                    title: '社群详情',
                    to: { path: '/communityDetail?id=' + this.$route.query.id },
                },
                {
                    title: '成员列表',
                },
            ],
            list: [],
        };
    },
    created() {
        this.getUserList();
    },
    methods: {
        getUserList() {
            this.$hao
                .getCommunityUsers({
                    communityId: this.$route.query.id,
                    pageNum: this.page,
                    pageSize: this.limit,
                })
                .then((res) => {
                    this.total = res.total;
                    this.list = res.rows;
                });
        },
        getUserInfo(item) {
            this.$router.push({ path: '/communityPersonnel', query: { item: JSON.stringify(item) } });
        },
    },
};
</script>

<style scoped lang="less">
.list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;

    .item {
        margin-bottom: 56px;
        cursor: pointer;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        img {
            // width: 100%;
            width: 120px;
            height: 120px;
            border-radius: 100%;

            filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
        }

        .title {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #333333;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin-top: 13px;
            width: 90%;
            .ellipsisLine(1);
        }
    }
}
</style>
